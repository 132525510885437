#analytics {
  #menu {
    padding-top: 10px;
  }

  #content {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex-basis: 100%;

      & > * {
        padding: 0 10px;
      }

      @media (min-width: 500px) {
        flex-basis: 50%;
      }
      @media (min-width: 1000px) {
        flex-basis: calc(100% / 3);
      }
    }
  }
  #summary {
    display: flex;
    margin-bottom: 5px;

    & > div {
      &:first-child {
        border-right: 1px solid rgb(25, 103, 210);
      }
      flex-basis: 50%;
      text-align: center;
      font-size: 1.6rem;
      &>span {
        font-size: 1.1rem;
      }
    }
  }
  .table {
    &#realtime-table > div > div:not(:first-child) {
      flex-basis: 15%;
    }

    &#referrers-table > div > div:not(:first-child) {
      flex-basis: 30%;
    }

    & > div {
      display: flex;
      border-bottom: 1px solid grey;

      & > div {
        padding: 10px 5px;
      }

      & > div:first-child {
        flex-basis: 70%;
      }

      & > div:not(:first-child) {
        text-align: right;
      }
    }

    .header {
      background-color: darkgrey;
    }
  }

  #last-visit {
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin: 0 5px;

      &:hover {
        cursor: pointer;
      }
    }

    & > div {
      & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      & > div:first-child {
        background-color: darkgrey;
        padding: 10px 5px;
      }

      span {
        padding-right: 10px;
      }
    }
  }
}

div.leaflet-tooltip-div {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin: 0 5px;
  }
}