$BACKGROUND: #faf9ff;
$BORDER-RADIUS: 28px;
$BACKGROUND-INPUT: #f3f3f3;

.alert-modal-backdrop {
  z-index: 1051 !important;
}

.alert-modal {
  z-index: 1060 !important;
}

.btn-warning {
  color: #212529 !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.side-menu-blog {
  background-color: white;
  position: fixed;
  height: calc(100vh - 66px);
  right: 0;
  top: 66px;
  box-shadow: 11px -10px 22px black;

  @media (max-width: 767px) {
    width: 40vw;
    right: -40vw;
    transition: all 0.5s ease-in-out;
    &.show {
      right: 0;
      z-index: 2;
    }
  }

  img {
    max-height: 120px;
    object-fit: contain;
  }
}

.editor {
  max-width: 1300px;
}

.sheet-details {
  .sheet-header {
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    color: white;
    text-shadow: #292929 0px 0 8px;
  }
  a {
    color: white;
  }
}

/* GENERATOR */

.generator-div {
  border: 1px solid #dbdbdb;
  border-top: none;
  border-radius: 0 0 3.6rem 3.6rem;
  margin-top: calc(-2rem - 1.875rem / 2);
  min-height: calc(2rem + 1.875rem / 2);

  & > div:first-child {
    padding: calc(2rem + 1.875rem / 2) 1rem 0.5rem 1rem;
  }

  & > div:last-child {
    padding-bottom: 0.5rem;
  }
}

.generator-title {
  padding: 2rem;
  margin-bottom: 0 !important;
  background-color: $BACKGROUND;
  border-radius: 3.6rem;
  position: relative;

  button {
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    right: 2rem;
    color: white;
    height: 2rem + 1.875rem;
    width: 2rem + 1.875rem;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;

    svg {
      transition: 350ms ease-in-out;
    }

    &:not(.open) {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.img-generator {
  width: 100%;
  max-height: 25vh;
  object-fit: contain;
}

.add-button-hover {
  height: 48px;
  & > button {
    display: none;
  }

  &:hover {
    button {
      display: inline-flex;
    }
  }
}

.add-button:hover {
  color: #8db5ff;
}

.add-item {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 120;
  top: -100%;
  background-color: rgba(87, 87, 87, 0.28);
  & > div:first-child {
    height: 100%;
    width: 100%;
  }
  &.show {
    top: 0;
    & > .MuiFormControl-root {
      top: 3%;
    }
  }
  & > .MuiFormControl-root {
    position: absolute;
    top: -20%;
    transition: top 0.3s linear;
    width: 80%;
    margin-left: 10%;
  }
}

.div-table > div {
  width: 100%;
}

.datepicker > div {
  width: 100%;
}

.MuiFormControl-root {
  .PrivateNotchedOutline-legendNotched-4 {
    visibility: visible;
    line-height: 1;
    margin-left: 10px;
  }
}
