.searchBar {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;

  & > div {
    flex: 1;
  }
}

.username {
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    margin-top: -3px;
  }
}
