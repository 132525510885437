.card {
  padding: 10px;
  overflow: hidden;
  .toolbar {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 13px;
    }
  }

  .table {
    border-radius: 4px;
    overflow: scroll;
    .header {
      background-color: rgba(44, 92, 112, 0.32);
    }
    .row {
      &:hover {
        background-color: rgba(44, 92, 112, 0.183);
      }
    }
  }

  .searchBar  {
    width: 100%;
    margin: 8px 0;
  }
}