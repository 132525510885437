@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap');

$BORDER-RADIUS: 28px;
$BACKGROUND-INPUT: #f3f3f3;
$BACKGROUND: #faf9ff;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: white !important;
  font-size: 1rem;
}

a {
  color: inherit;
  &:not(.underline) {
    text-decoration: none;
  }
  &:hover {
    color: inherit;
  }
}

* {
  box-sizing: border-box;
}

*:focus-visible {
  outline: none !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.p-10 {
  padding: 5rem;
}

.w-70 {
  max-width: 70vw !important;
}

@media (min-width: 768px) {
  .p-md-10 {
    padding: 5rem;
  }

  .w-md-70 {
    max-width: 70vw !important;
  }
}

.container {
  @media (min-width: 1400px) {
    max-width: 80vw;
  }
}

.delete-button {
  position: relative;
  height: 56px;

  & > *:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.vh100 {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.center-v {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.header-page {
  width: 100%;
  position: relative;
  overflow-x: clip;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .bubble {
    top: -10vh;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    max-width: 40vw;
    max-height: 30vh;
  }
}

.cercle {
  background: radial-gradient(#8ebdff47 0%, #ffffff00 50%);
  position: absolute;
  width: 40vw;
  height: 40vw;
  bottom: 0;
  transform: translate(-50%, 50%);

  &.pink {
    background: radial-gradient(#f6dff1, #ffffff00 50%);
  }

  &.top-right {
    top: 0;
    bottom: auto;
    right: 0;
    transform: translate(50%, -50%);
  }

  &.bottom-right {
    right: 0;
    transform: translate(50%, 50%);
  }
}

.plain-cercle {
  background: #f1f0ff;
  border-radius: 50%;
  position: absolute;
  animation-delay: random(2s);
  animation: wubble linear alternate-reverse 5s infinite;
  width: 8vw;
  height: 8vw;
  @media (min-width: 768px) {
    width: 3vw;
    height: 3vw;
  }
}

.subscription-card {
  background-color: #faf9ff;
  border: 1px solid #ebebf1;
  border-radius: 20px;
  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}

@keyframes wubble {
  0% {
    transform: translate3d(-6px, 7px, 0);
  }

  30% {
    transform: translate3d(0px, 4px, 0);
  }

  50% {
    transform: translate3d(4px, 10px, 0);
  }

  70% {
    transform: translate3d(6px, 4px, 0);
  }

  100% {
    transform: translate3d(0px, -2px, 0);
  }
}

/* MODAL */
.modal-content {
  border-radius: 1rem;
  border: none;
}

.modal-header {
  position: relative;
  border-bottom: none;
  &:after {
    position: absolute;
    content: '';
    height: 1px;
    bottom: -4px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 20%;
    background: #e4e4e4;
  }
}

.modal-footer {
  border-top: none;
}

.modal-title {
  width: calc(100% - 2rem);
  font-weight: 600;
}

.second-modal-backdrop {
  z-index: 1070;
}

.second-modal {
  z-index: 1071;
}

/* HOME */
.home-sheet-div {
  margin-top: 1.2rem;
  background-color: $BACKGROUND;
  overflow-x: clip;

  img {
    width: 160%;
    margin-top: -1rem;
  }
}

/* GENERATOR */

.generator-div {
  border: 1px solid #dbdbdb;
  border-top: none;
  border-radius: 0 0 3.6rem 3.6rem;
  margin-top: calc(-2rem - 1.875rem / 2);
  min-height: calc(2rem + 1.875rem / 2);

  & > div:first-child {
    padding: calc(2rem + 1.875rem / 2) 1rem 0.5rem 1rem;
  }

  & > div:last-child {
    padding-bottom: 0.5rem;
  }
}

.generator-title {
  padding: 2rem;
  margin-bottom: 0 !important;
  background-color: $BACKGROUND;
  border-radius: 3.6rem;
  position: relative;

  button {
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    right: 2rem;
    color: white;
    height: 2rem + 1.875rem;
    width: 2rem + 1.875rem;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;

    svg {
      transition: 350ms ease-in-out;
    }

    &:not(.open) {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.img-generator {
  width: 100%;
  max-height: 25vh;
  object-fit: contain;
}

.add-button-hover {
  height: 48px;
  & > button {
    display: none;
  }

  &:hover {
    button {
      display: inline-flex;
    }
  }
}

.add-button:hover {
  color: #8db5ff;
}

.add-item {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 120;
  top: -100%;
  background-color: rgba(87, 87, 87, 0.28);
  & > div:first-child {
    height: 100%;
    width: 100%;
  }
  &.show {
    top: 0;
    & > .MuiFormControl-root {
      top: 3%;
    }
  }
  & > .MuiFormControl-root {
    position: absolute;
    top: -20%;
    transition: top 0.3s linear;
    width: 80%;
    margin-left: 10%;
  }
}

/* FOOTER */
.footer {
  position: relative;
  border-top: 1px solid grey;
  overflow: hidden;

  .row {
    position: relative;
  }

  & > img {
    max-height: 10vh;
  }

  .cercle {
    height: 80vw;
    width: 80vw;
  }
}

/* SEARCH */
.search-page {
  .big-icon {
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    width: 5vw;
    height: 5vw;
    color: white;
  }

  .info {
    @media (min-width: 600px) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pa-menu-paper {
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 1250;
    right: -100%;
    display: flex;
    transition: right 0.3s linear;
    overflow-y: scroll;
    &.show {
      right: -15px;
      transition: right 0.3s linear;
    }
  }
}

.sheet {
  border-radius: 1rem !important;
  background-color: #f1f0ff !important;

  .card-footer {
    background-color: transparent !important;
    border-top: none !important;
  }
}

.review-modal {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 1px;
    bottom: -10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 50%;
    background: #e4e4e4;
  }

  svg {
    font-size: 2rem !important;
  }
}

.sheet-details {
  .sheet-header {
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    color: white;
    text-shadow: #292929 0px 0 8px;
  }
}

/* INPUT */

.input-file {
  background-color: $BACKGROUND-INPUT;
  resize: none;
  border-radius: $BORDER-RADIUS !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-weight: 500 !important;
  font-family: 'Raleway', sans-serif !important;
  font-size: 16px !important;
  color: #7a7a7a !important;
  position: relative;
  height: 56px;

  & > span {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    padding: 0 20px;
  }

  & > button {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 4px;
    background: linear-gradient(133deg, #8ebdff 15%, #0347fe 85%);
    color: white;
  }
}

.ql-toolbar {
  border-radius: 3rem;
  border: none !important;
  background-color: $BACKGROUND-INPUT;
  margin: 5px 0;
  padding: 15px 12px !important;
}

.ql-container {
  background-color: $BACKGROUND-INPUT;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: $BORDER-RADIUS !important;
  height: auto !important;

  .ql-editor {
    font-size: 16px;
  }
}

/* COLOR */

.underline-href {
  a {
    text-decoration: underline;
  }
}

.info {
  color: #bababa;
}

.info2 {
  color: #717171;
}

.yellow {
  color: #ffc107;
}

.text-alert {
  color: darkred;
}

.green {
  color: green;
}

/* FONT */
.f-15 {
  font-size: 0.938rem !important;
}

.f-12 {
  font-size: 0.75rem !important;
}

/* MYACCOUNT */
.myaccount {
  .avatar-myaccount {
    height: 10vw !important;
    width: 10vw !important;
  }

  .avatar-bg {
    box-shadow: 1px 5px 35px #8db5ff;
  }

  .title {
    font-size: 3rem;
    font-weight: 700;

    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .informations-div {
    background-color: $BACKGROUND;
    border-radius: 2rem;
    .sub-title {
      font-weight: 700;
    }
  }
}

.balance-input {
  width: 70%;

  input {
    text-align: center;
    font-size: 8rem !important;
    background-color: #ffffff !important;
  }

  .MuiInputAdornment-root > p {
    font-size: 8rem;
  }
}

.modal-img {
  max-height: 300px;
  max-width: 100%;
}

.balance-amount {
  font-weight: bold;
  color: black;
}
