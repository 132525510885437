.modal {
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  background-color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 20px;
  border-radius: 4px;
  display: flex;

  @media (max-width: 600px) {
    max-width: 90% !important;
    width: 90% !important;
  }

  &[aria-size="small"] {
    width: 40%;
    flex-wrap: wrap;
  }

  &[aria-size="medium"] {
    width: 60%;
  }

  &[aria-size="large"] {
    width: 80%;
    max-width: 1600px;
  }

  &[aria-size="auto"] {
    width: unset;
    max-width: 90%;
  }

  & > div {
    position: relative;
    align-self: stretch;
    width: 100%;
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .goBackBtn {
      background-color: var(--primary);
      color: black;
      position: absolute;
      top: -32px;
      left: -32px;
      overflow: auto;
    }
  }

  .header {
    width: 100%;
    position: relative;
    text-align: center;
    flex: 1;
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      bottom: -4px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 20%;
      background-color: rgb(59, 109, 178);
    }
  }

  .content {
    height: 100%;
    // overflow-y: auto;
    // overflow: visible;
    overflow: auto;
  }

  .footer {
    width: 100%;
    margin-top: 10px;
    position: relative;
    flex: 1;
    justify-content: flex-end;
    display: flex;
    gap: 12px;
    &::before {
      position: absolute;
      content: "";
      height: 1px;
      top: -4px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 20%;
      background-color: rgb(59, 109, 178);
    }
  }

  .content {
    padding: 5px;
  }
}
