#analytics #menu {
  padding-top: 10px;
}
#analytics #content {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
#analytics #content > div {
  flex-basis: 100%;
}
#analytics #content > div > * {
  padding: 0 10px;
}
@media (min-width: 500px) {
  #analytics #content > div {
    flex-basis: 50%;
  }
}
@media (min-width: 1000px) {
  #analytics #content > div {
    flex-basis: 33.3333333333%;
  }
}
#analytics #summary {
  display: flex;
  margin-bottom: 5px;
}
#analytics #summary > div {
  flex-basis: 50%;
  text-align: center;
  font-size: 1.6rem;
}
#analytics #summary > div:first-child {
  border-right: 1px solid rgb(25, 103, 210);
}
#analytics #summary > div > span {
  font-size: 1.1rem;
}
#analytics .table#realtime-table > div > div:not(:first-child) {
  flex-basis: 15%;
}
#analytics .table#referrers-table > div > div:not(:first-child) {
  flex-basis: 30%;
}
#analytics .table > div {
  display: flex;
  border-bottom: 1px solid grey;
}
#analytics .table > div > div {
  padding: 10px 5px;
}
#analytics .table > div > div:first-child {
  flex-basis: 70%;
}
#analytics .table > div > div:not(:first-child) {
  text-align: right;
}
#analytics .table .header {
  background-color: darkgrey;
}
#analytics #last-visit img {
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 5px;
}
#analytics #last-visit img:hover {
  cursor: pointer;
}
#analytics #last-visit > div > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#analytics #last-visit > div > div:first-child {
  background-color: darkgrey;
  padding: 10px 5px;
}
#analytics #last-visit > div span {
  padding-right: 10px;
}

div.leaflet-tooltip-div img {
  width: 20px;
  height: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 5px;
}/*# sourceMappingURL=analytics.css.map */