@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

.sheet-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  & > div {
    font-family: var(--default-font);
    font-size: var(--default-font-size) !important;
    padding: 16px;
  }

  & > .sheet-preview {
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .title {
    margin-top: 15px;
  }

  .content {
    padding: 0 20px;
  }

  & > div.templateClassic {
    .header {
      // margin-left: 2vw;
      // margin-right: 2vw;
      margin-bottom: 2vw;
      border-radius: 50px;
      border: 2px solid #ffc44f;
      padding: 10px;

      h1 {
        margin-top: 0 !important;
        margin-left: 0 !important;
      }

      & > .row {
        align-items: center;
        & > .col-auto:first-child,
        & > .col-auto:last-child {
          width: 22%;
        }
      }
      & > .col-auto:first-child,
      & > .col-auto:last-child {
        width: 22%;
      }
    }
  }

  .text-center {
    text-align: center !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .h1.title {
    font-size: 1.3rem;
  }

  .definition,
  .body,
  .resum {
    // margin-right: 2vw;
    // margin-left: 2vw;
    padding: 10px;
    border: 1px solid #00aca4;
    border-radius: 15px;
    margin-top: 15px;
  }

  .resum {
    border-color: #ff005e;
  }

  .definition > h4,
  .resum > h4,
  .definition > h2 {
    font-size: calc(var(--default-font-size) - 14px + 1.2rem);
    padding-right: 5px;
    padding-left: 5px;
    margin-top: calc(-1rem - 5px);
    background-color: white;
    width: fit-content;
    color: #00aca4;
  }

  .definition-recap {
    border: 1px solid #a50000;
    margin-top: 10rem;
  }

  .definition-recap > h2 {
    font-size: 1.3rem;
    // padding-right: 5px;
    // padding-left: 5px;
    // margin-left: 0px;
    margin-top: calc(-1.1rem - 5px);
    background-color: white;
    width: fit-content;
    color: #a50000;
  }

  .resum > h4 {
    color: #ff005e;
  }

  .boxy {
    // margin-left: 2vw;
  }

  .boxy > .keyword {
    font-weight: bold;
  }

  .boxy > .keyword::first-letter {
    text-transform: uppercase;
  }

  .boxy > .explain::first-letter {
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0.1rem;
  }

  ul {
    margin-bottom: 0.4rem;
  }

  p > img {
    width: 100%;
  }

  .zone-img > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }

  .zone-img > p {
    text-align: center;
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 15px;
    // margin-left: 2vw;
    margin-bottom: 0 !important;
  }

  h1 {
    font-size: 2rem;
  }

  pre {
    background-color: black;
    border-radius: 3px;
    padding: 2px 7px;
    color: white;
    white-space: normal;
  }

  .subscription-card {
    &:hover {
      box-shadow: 0px 1px 17px 11px rgba(255, 255, 255, 0.1);
    }
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-indent-1 {
    margin-left: 2.5rem;
  }

  .ql-indent-2 {
    margin-left: calc(2.5rem * 2);
  }

  .ql-indent-3 {
    margin-left: calc(2.5rem * 3);
  }

  .ql-indent-4 {
    margin-left: calc(2.5rem * 4);
  }

  .ql-indent-5 {
    margin-left: calc(2.5rem * 5);
  }

  .ql-indent-6 {
    margin-left: calc(2.5rem * 6);
  }

  .ql-indent-7 {
    margin-left: calc(2.5rem * 7);
  }

  .ql-indent-8 {
    margin-left: calc(2.5rem * 8);
  }

  // template
  & > div.templateModern {
    display: flex;
    flex-direction: row;
    padding: 0px;

    .header {
      width: 30%;
      min-height: 1234.2px;
      margin: 0;
      border: none;
      border-radius: 0;
      & > div {
        color: white;
        width: 973px;
        transform: rotate(270deg) translateX(-100%);
        transform-origin: top left;
        flex-direction: column;
        & > div:nth-child(2n) {
          order: 0;
        }
        & > div {
          order: 1;
          text-align: end !important;
        }
      }
    }
    .content {
      width: 70%;
    }
  }

  & > div.templateAesthetic {
    .header {
      & > div {
        align-items: center;
        & > div:first-child,
        & > div:last-child {
          width: 22%;
        }
      }
      h1 {
        color: var(--default);
        margin-top: 0;
        font-size: 3rem;
      }
    }

    .content {
      overflow: hidden;
      h1,
      h2,
      h3 {
        position: relative;
        margin-bottom: 20px !important;
        &::after {
          width: calc(100%);
          height: 5px;
          background-color: var(--default);
          content: ' ';
          position: absolute;
          top: 50%;
          transform: translate(10px, -50%);
        }
      }

      .definition,
      .body,
      .resum {
        // margin-left: 1vw;
        border: none;
      }

      .definition > h4,
      .resum > h4,
      .definition > h2 {
        color: var(--default);
      }
    }
  }

  & > div.templateDefault {
    .header {
      background: #f7f8fc;
      & > div {
        align-items: center;
        flex: 1;
        padding: 16px 12px;
        color: #283f9a;
        h1 {
          margin-top: 0 !important;
          // margin-left: 0 !important;
          font-weight: 400;
          &::before {
            content: none;
          }
        }
        & > div:first-child,
        & > div:last-child {
          width: 25%;
        }
      }
    }

    .title {
      // margin-right: 2vw;
      // margin-left: 2vw;
      position: relative;
      min-height: 21px;
      & > * {
        margin-left: 30px;
      }
      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 16px;
        height: 16px;
        background: url('/Icons/title.svg') #ffffff;
        content: ' ';
        background-size: contain;
        transform: translateY(-50%);
      }
    }

    .definition,
    .body,
    .resum {
      border: none;
      padding: 0;
      & > h4 {
        margin-top: 0;
      }
    }

    .resum {
      & > h4 {
        color: #d40000;
      }
      .boxy {
        padding-left: 5px;
      }
    }

    .text {
      position: relative;
      padding-left: 30px;
      &:before {
        position: absolute;
        left: 0;
        top: 5px;
        width: 16px;
        height: 16px;
        background: url('/Icons/text.svg'), #ffffff;
        content: ' ';
        background-size: contain;
      }

      &:after {
        position: absolute;
        left: 7px;
        top: 29px;
        width: 2px;
        height: calc(100% - 13px - 20px);
        background-color: #465154;
        content: ' ';
      }
    }

    .zone-img {
      position: relative;
      padding-left: 30px;
      padding-top: 10px;

      p {
        margin-top: 8px;
        font-style: normal;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 13px;
        width: 16px;
        height: 16px;
        background: url('/Icons/image.svg'), #ffffff;
        content: ' ';
        background-size: contain;
      }

      &:after {
        position: absolute;
        left: 7px;
        top: 37px;
        width: 2px;
        height: calc(100% - 34px - 20px + 10px);
        background-color: #14b0ba;
        content: ' ';
      }
    }

    .definition {
      position: relative;
      padding-left: 30px;
      & > div {
        // margin-left: 0;
      }
      &:before {
        position: absolute;
        left: 0;
        top: 3px;
        width: 16px;
        height: 16px;
        background: url(/Icons/definition.svg), #ffffff;
        content: ' ';
        background-size: contain;
      }
      &:after {
        position: absolute;
        left: 7px;
        top: 25px;
        width: 2px;
        height: calc(100% - 14px - 20px);
        background-color: #df2929;
        content: ' ';
      }
    }
  }

  & > div.templateHandwritten {
    .header {
      & > div {
        align-items: center;
        padding: 16px 12px;
        & > div {
          display: flex;
          justify-content: center;
        }
        h1 {
          margin-top: 0 !important;
          font-weight: 600;
          width: fit-content;
          position: relative;
          &:before {
            position: absolute;
            left: -5%;
            top: -5%;
            width: 110%;
            height: 110%;
            background-color: var(--default);
            opacity: 0.1;
            content: ' ';
          }
        }
        & > div:first-child,
        & > div:last-child {
          display: none;
        }
      }
    }

    .definition,
    .body,
    .resum {
      border: none;
      padding: 0;
      & > h4 {
        margin-top: 0;
      }
    }

    .title {
      color: var(--default);
    }

    .definition {
      position: relative;
      padding-left: 30px;
      padding-bottom: 30px;

      h4 {
        padding-left: 0;
        color: var(--default);
        margin-left: -5px;
        font-size: 1.2rem;
      }

      &:before {
        position: absolute;
        left: 7px;
        top: 0;
        width: 2px;
        height: calc(100% - 30px);
        background-color: var(--default);
        content: ' ';
      }

      &:after {
        position: absolute;
        right: -36px;
        bottom: 10px;
        width: 80%;
        height: 2px;
        background-color: var(--default);
        content: ' ';
      }

      & > div:last-child::after {
        position: absolute;
        right: calc(80% - 36px);
        bottom: 6px;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        background-color: var(--default);
        content: ' ';
      }
    }

    .resum {
      position: relative;
      padding-top: 20px;

      h4 {
        padding-left: 0;
        color: var(--default);
      }

      &:before {
        position: absolute;
        left: -36px;
        top: 0;
        width: 80%;
        height: 2px;
        background-color: var(--default);
        content: ' ';
      }

      & > *:first-child::before {
        position: absolute;
        left: calc(80% - 36px);
        top: -4px;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        background-color: var(--default);
        content: ' ';
      }

      &::after {
        width: 102%;
        height: 100%;
        content: ' ';
        background-color: var(--default);
        opacity: 0.1;
        position: absolute;
        top: 10px;
        left: -1%;
      }
    }
  }
}
