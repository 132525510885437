#visualizer,
#forpdf {
  &#forpdf {
    display: none;
    width: 875.6px;
    & > div {
      border: unset;
    }
    .sheet-container {
      position: relative;
    }
  }
  &#visualizer > div {
    height: 1234.2px;
    width: 875.6px;
    overflow-y: scroll;
    left: 50%;
    top: 50%;
  }
  height: 100%;
  width: 100%;
  align-items: center;
  // aspect-ratio: 21 / 29.7;
  margin: auto;
  & > div {
    background-color: white;
    transform-origin: top left;
    border: 1px solid #d8d8d8;
    position: relative;
    width: 875.6px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .sheet-container {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      min-height: 100%;
    }
  }
}

@media print {
  @page {
    size: 875.6px 1234.2px;
    margin: 20px;
  }

  body {
    visibility: hidden;
  }
  #visualizer {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    & > div {
      transform: none !important;
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
      overflow-y: visible !important;
      border: none !important;
    }
  }
}
