.buttons > div {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.aiIcon {
  font-size: inherit !important;
  color: #fe491a;
  margin-right: 5px;
}

.filter {
  padding: 0 8px;
}
